import { permissoes } from 'src/app/consts';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { DashboardService } from '../../services';
import {
  DailyLineChartData,
  PerformanceChartData,
  ProjectStatData,
  RevenueChartData,
  ServerChartData,
  SupportRequestData,
  VisitsChartData
} from '../../models';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})


export class DashboardPageComponent implements OnInit {
  // public dailyLineChartData$: Observable<DailyLineChartData>;
  // public performanceChartData$: Observable<PerformanceChartData>;
  // public revenueChartData$: Observable<RevenueChartData>;
  // public serverChartData$: Observable<ServerChartData>;
  // public supportRequestData$: Observable<SupportRequestData[]>;
  // public visitsChartData$: Observable<VisitsChartData>;
  // public projectsStatsData$: Observable<ProjectStatData>;
  public idClienteUsuario
  permissoes = permissoes

  constructor(private service: DashboardService,
    private route: Router) {

      this.idClienteUsuario = JSON.parse(localStorage.getItem('usuarioCompleto'))

    // this.dailyLineChartData$ = this.service.loadDailyLineChartData();
    // this.performanceChartData$ = this.service.loadPerformanceChartData();
    // this.revenueChartData$ = this.service.loadRevenueChartData();
    // this.serverChartData$ = this.service.loadServerChartData();
    // this.supportRequestData$ = this.service.loadSupportRequestData();
    // this.visitsChartData$ = this.service.loadVisitsChartData();
    // this.projectsStatsData$ = this.service.loadProjectsStatsData();
  }

  ngOnInit(): void {
  }

  navigateCadastroClientes(){
    this.route.navigate(['pages/cadastros/clientes'])
  }

  navigateCadastroCategorias(){
    this.route.navigate(['pages/cadastros/categorias'])
  }

  navigateConsultaProdutos(){
    this.route.navigate(['pages/operacoes/consulta-de-produtos'])
  }

  navigateRevisaoProdutos(){
    this.route.navigate(['pages/operacoes/revisao'])
  }


}
