import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class PagesGuard implements CanActivate {

constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      const tipoUsuario = localStorage.getItem('usuarioCompleto')

      if(tipoUsuario == '0'){
        return true
      }

      else{
        this.router.navigate(['/pages/sem-permissao']);
        return false
      }
  }

}
