<form [formGroup]="formularioCategorias">
    <mat-card>
        <mat-card-header>
            <mat-card-title class="matCard-Font">Categorias</mat-card-title>
        </mat-card-header>
        <br>
        <mat-card-content>
            <div class="row">
                <div class="col-sm-1 col-12"></div>
                <div class="col-sm-4 col-12">
                    <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                        <mat-label>Categoria</mat-label>
                        <input formControlName="Nome" matInput type="text">
                        <button mat-button mat-icon-button matSuffix aria-label="Clear" (click)="pesquisarCategorias()">
              <mat-icon>search</mat-icon>
            </button>
                    </mat-form-field>
                </div>
                <div class="col-sm-2 col-12">
                    <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                        <mat-label>NCM</mat-label>
                        <input maxlength="8" formControlName="Ncm" matInput type="text" readonly>
                        <button *ngIf="formularioCategorias.value.Ncm == ''" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="pesquisarNcm()">
                          <mat-icon>search</mat-icon>
                        </button>
                        <button *ngIf="formularioCategorias.value.Ncm != ''" mat-button mat-icon-button matSuffix aria-label="Clear" (click)="limparNcm()">
                          <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div class="col-sm-2 col-12">
                    <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                        <mat-label>Cest</mat-label>
                        <input formControlName="Cest" matInput type="text" maxlength="7">
                    </mat-form-field>
                </div>
                <div class="col-sm-2 col-12 align-item-center">
                    <mat-form-field class="formField-Font" appearance="fill" style="width: 100%;">
                        <mat-label>Natureza de receita</mat-label>
                        <input formControlName="NaturezaReceita" matInput type="text" maxlength="3">
                        <mat-hint *ngIf="formularioCategorias.errors?.valorNaturezaInvalido">Obrigatório</mat-hint>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-1 col-12"></div>
                <div class="col-sm-10 col-12">
                    <mat-form-field class="formField-Font" appearance="fill" style="width: 100%">
                        <mat-label>Palavras chave</mat-label>
                        <mat-chip-list #chipList>
                            <mat-chip *ngFor="let palavra of palavrasChaves" [selectable]="selectable" [removable]="removable" (removed)="remove(palavra)">
                                {{palavra.Palavra}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input placeholder="Nova Palavra..." [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                    </mat-form-field>
                </div>
            </div>
            <mat-card class="mat-elevation-z0">
                <mat-card-title class="matCard-Font">Tributação Federal</mat-card-title>
                <br>

                <mat-form-field class="formField-Font" appearance="fill" style="width: 42.5%; margin-left: 6.6%;">
                    <mat-label>Situação do Pis / Cofins</mat-label>
                    <mat-select formControlName="SituacaoPisCofinsEntrada" (selectionChange)="preenchePisCofins($event)">
                        <mat-option *ngFor="let item of ListaSituacaoPISCOFINS" [value]="item.Id">
                            {{item.Nome}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="row justify-content-center">
                    <mat-card class="col-sm-5 col-12 mat-elevation-z0" style="background-color:#f8f8ff;">
                        <p class="subTitleFederal">Informações Cumulativas - Entrada</p>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST PIS</mat-label>
                                <mat-select formControlName="CstPisCumulativoEntrada">
                                    <mat-option *ngFor="let item of ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST COFINS</mat-label>
                                <mat-select formControlName="CstCofinsCumulativoEntrada">
                                    <mat-option *ngFor="let item of ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Pis</mat-label>
                                <input formControlName="AliquotaPisCumulativoEntrada" matInput type="number">
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Cofins</mat-label>
                                <input formControlName="AliquotaCofinsCumulativoEntrada" matInput type="number">
                            </mat-form-field>
                        </div>
                    </mat-card>
                    <mat-card class="col-sm-5 col-12 mat-elevation-z0" style="background-color: #fff0f5;">
                        <p class="subTitleFederal">Informações Cumulativas - Saída</p>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST PIS</mat-label>
                                <mat-select formControlName="CstPisCumulativoSaida">
                                    <mat-option *ngFor="let item of ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST COFINS</mat-label>
                                <mat-select formControlName="CstCofinsCumulativoSaida">
                                    <mat-option *ngFor="let item of ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Pis</mat-label>
                                <input formControlName="AliquotaPisCumulativoSaida" matInput type="number">
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Cofins</mat-label>
                                <input formControlName="AliquotaCofinsCumulativoSaida" matInput type="number">
                            </mat-form-field>
                        </div>


                    </mat-card>
                </div>

                <div class="row justify-content-center">
                    <mat-card class="col-sm-5 col-12 mat-elevation-z0" style="background-color: #f8f8ff;">
                        <p class="subTitleFederal">Informações não cumulativas - Entrada</p>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST PIS</mat-label>
                                <mat-select formControlName="CstPisNaoCumulativoEntrada">
                                    <mat-option *ngFor="let item of ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST COFINS</mat-label>
                                <mat-select formControlName="CstCofinsNaoCumulativoEntrada">
                                    <mat-option *ngFor="let item of ListasCSTPisCofinsFederal.ListaCTSPISCOFINSEntrada" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Pis</mat-label>
                                <input formControlName="AliquotaPisNaoCumulativoEntrada" matInput type="number">
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Cofins</mat-label>
                                <input formControlName="AliquotaCofinsNaoCumulativoEntrada" matInput type="number">
                            </mat-form-field>
                        </div>
                    </mat-card>
                    <mat-card class="col-sm-5 col-12 mat-elevation-z0" style="background-color:	#fff0f5;">
                        <p class="subTitleFederal">Informações não cumulativas - Saída</p>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST PIS</mat-label>
                                <mat-select formControlName="CstPisNaoCumulativoSaida">
                                    <mat-option *ngFor="let item of ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>CST COFINS</mat-label>
                                <mat-select formControlName="CstCofinsNaoCumulativoSaida">
                                    <mat-option *ngFor="let item of ListasCSTPisCofinsFederal.ListaCTSPISCOFINSSaida" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="row justify-content-center">
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Pis</mat-label>
                                <input formControlName="AliquotaPisNaoCumulativoSaida" matInput type="number">
                            </mat-form-field>
                            <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                <mat-label>Alíq. Cofins</mat-label>
                                <input formControlName="AliquotaCofinsNaoCumulativoSaida" matInput type="number">
                            </mat-form-field>
                        </div>
                    </mat-card>
                </div>
            </mat-card>
        </mat-card-content>
    </mat-card>

    <div class="justify-content-center">
        <button mat-raised-button style="width: 97%; margin: 15px;" (click)="addNovoEstado()">
      <mat-icon style="vertical-align:-35%;">control_point</mat-icon>&nbsp; Adicionar tributação estadual
    </button>
    </div>

    <div formArrayName="ListaTributacaoEstadual" *ngFor="let estado of EstadualControls, let i = index">
        <form [formGroup]="estado">
            <div style="margin: 15px;">
                <mat-accordion class="example-headers-align" displayMode="default" [multi]="true" [hideToggle]="false">
                    <mat-expansion-panel [hideToggle]="false" [expanded]="false">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="tE-Font" *ngIf="estado.value.Id != '00000000-0000-0000-0000-000000000000'">
                                <strong>Tributação Estadual - {{estado.value.Uf.UF}}</strong></mat-panel-title>
                            <mat-panel-title class="tE-Font" *ngIf="estado.value.Id == '00000000-0000-0000-0000-000000000000'">
                                Tributação Estadual</mat-panel-title>
                        </mat-expansion-panel-header>

                        <mat-card class="mat-elevation-z0">

                            <div class="row justify-content-center">
                                <mat-form-field class="formField-Font" appearance="fill" style="width: 13%;" *ngIf="estado.value.Id == '00000000-0000-0000-0000-000000000000'">
                                    <mat-label>Uf</mat-label>
                                    <mat-select formControlName="Uf">
                                        <mat-option *ngFor="let estadoUf of ListaUf" [value]="estadoUf.idUF">{{estadoUf.UF}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="formField-Font" appearance="fill" style="width: 87%;" *ngIf="estado.value.Id != guidInicial">
                                    <mat-label>Situação ICMS</mat-label>
                                    <mat-select formControlName="SituacaoIcmsEntrada">
                                        <mat-option *ngFor="let item of ListaCSTICMS.SituacaoICMS" [value]="item.Id">
                                            {{item.Nome}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="formField-Font" appearance="fill" style="width: 74%;" *ngIf="estado.value.Id == guidInicial">
                                    <mat-label>Situação ICMS</mat-label>
                                    <mat-select formControlName="SituacaoIcmsEntrada">
                                        <mat-option *ngFor="let item of ListaCSTICMS.SituacaoICMS" [value]="item.Id">
                                            {{item.Nome}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="formField-Font" appearance="fill" style="width: 87%;">
                                    <mat-label>Situação CSOSN</mat-label>
                                    <mat-select formControlName="SituacaoCsosnEntrada">
                                        <mat-option *ngFor="let item of ListaCSTICMS.SituacaoCSOSN" [value]="item.Id">
                                            {{item.Nome}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="row justify-content-center">
                                    <mat-form-field class="formField-Font" appearance="fill" style="width: 78.8%;">
                                        <mat-label>CSOSN</mat-label>
                                        <mat-select formControlName="CsosnEntrada">
                                            <mat-option *ngFor="let item of ListaCSTICMS.ListaCSOSN" [value]="item.Id">
                                                {{item.Id + item.Descricao}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-slide-toggle formControlName="Fecp" style="width:10%;">Fecp</mat-slide-toggle>
                                </div>
                                <mat-card class="col-sm-5 col-12 mat-elevation-z0" style="background-color: #f8f8ff;">
                                    <p class="subTitleFederal">Informações da Entrada - ICMS</p>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>CST</mat-label>
                                            <mat-select formControlName="CstIcmsEntrada">
                                                <mat-option *ngFor="let item of ListaCSTICMS.ListaCSTIcms" [value]="item">
                                                    {{item}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>Alíq.</mat-label>
                                            <mat-select formControlName="AliquotaIcmsEntrada">
                                                <mat-option *ngFor="let item of ListaCSTICMS.AliquotaICMS" [value]="item">
                                                    {{item}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-hint *ngIf="estado.errors?.valorAliquotaEntradaInvalido">Obrigatório</mat-hint>

                                        </mat-form-field>
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>Alíq. ST</mat-label>
                                            <input formControlName="AliquotaIcmsStEntrada" matInput type="number">
                                            <mat-hint *ngIf="estado.errors?.valorAliquotaStEntradaInvalido">Obrigatório</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Interno</mat-label>
                                            <input formControlName="MvaInternoEntrada" matInput type="number">
                                        </mat-form-field>
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Externo 12%</mat-label>
                                            <input formControlName="MvaExterno12Entrada" matInput type="number">
                                        </mat-form-field>
                                    </div>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Externo 7%</mat-label>
                                            <input formControlName="MvaExterno7Entrada" matInput type="number">
                                        </mat-form-field>
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Externo 4%</mat-label>
                                            <input formControlName="MvaExterno4Entrada" matInput type="number">
                                        </mat-form-field>
                                    </div>
                                </mat-card>
                                <mat-card class="col-sm-5 col-12 mat-elevation-z0" style="background-color: #fff0f5;">
                                    <p class="subTitleFederal">Informações da Saída - ICMS</p>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>CST</mat-label>
                                            <mat-select formControlName="CstIcmsSaida">
                                                <mat-option *ngFor="let item of ListaCSTICMS.ListaCSTIcms" [value]="item">
                                                    {{item}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>Alíq.</mat-label>
                                            <mat-select formControlName="AliquotaIcmsSaida">
                                                <mat-option *ngFor="let item of ListaCSTICMS.AliquotaICMS" [value]="item">
                                                    {{item}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-hint *ngIf="estado.errors?.valorAliquotaSaidaInvalido">Obrigatório</mat-hint>
                                        </mat-form-field>
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>Alíq. ST</mat-label>
                                            <input formControlName="AliquotaIcmsStSaida" matInput type="number">
                                            <mat-hint *ngIf="estado.errors?.valorAliquotaStSaidaInvalido">Obrigatório</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Interno</mat-label>
                                            <input formControlName="MvaInternoSaida" matInput type="number">
                                        </mat-form-field>
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Externo 12%</mat-label>
                                            <input formControlName="MvaExterno12Saida" matInput type="number">
                                        </mat-form-field>
                                    </div>
                                    <div class="row justify-content-center">
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Externo 7%</mat-label>
                                            <input formControlName="MvaExterno7Saida" matInput type="number">
                                        </mat-form-field>
                                        <mat-form-field class="formField-Font" appearance="fill" style="width: 45%;">
                                            <mat-label>MVA Externo 4%</mat-label>
                                            <input formControlName="MvaExterno4Saida" matInput type="number">
                                        </mat-form-field>
                                    </div>


                                </mat-card>
                                <div class="row justify-content-center">

                                    <mat-form-field class="formField-Font" appearance="fill" style="width: 44.6%;">
                                        <mat-label>Redução base de Cáculo</mat-label>
                                        <input formControlName="ReducaoBaseCalculo" matInput type="number">
                                        <mat-hint *ngIf="estado.errors?.ReducaoBaseCalculoInvalido">Obrigatório</mat-hint>

                                    </mat-form-field>
                                    <mat-form-field class="formField-Font" appearance="fill" style="width: 44.6%;">
                                        <mat-label>Motivo Desoneração</mat-label>
                                        <mat-select formControlName="MotivoDesoneracao">
                                            <mat-option *ngFor="let item of ListaCSTICMS.MotivoDesoneracao" [value]="item.Id">
                                                {{item.Nome}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-hint *ngIf="estado.errors?.MotivoDesoneracaoInvalido">Obrigatório</mat-hint>
                                    </mat-form-field>
                                </div>

                                <mat-form-field class="formField-Font" appearance="fill" style="width: 43.7%;">
                                    <mat-label>Alíquota Desoneração</mat-label>
                                    <input formControlName="AliquotaCalculoDesoneracao" matInput type="number">
                                    <mat-hint *ngIf="estado.errors?.valorAliquotaDesInvalido">Obrigatório</mat-hint>
                                </mat-form-field>

                                <mat-form-field class="formField-Font" appearance="fill" style="width: 43.7%;">
                                    <mat-label>Tipo de Desoneração</mat-label>
                                        <input maxlength="20" formControlName="TipoDesoneracao" matInput type="text">
                                    <mat-hint *ngIf="estado.errors?.TipoDesoneracaoInvalido">Obrigatório</mat-hint>
                                </mat-form-field>

                            </div>


                        </mat-card>
                        <div class="row justify-content-center">
                            <button mat-raised-button (click)="excluirEstado(i)" color="warn" style="width: 25%;">Excluir
                tributação estadual</button>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </form>
    </div>
</form>

<app-auditoria *ngIf="formularioCategorias.value.Id != guidInicial" [DataCadastro]="formularioCategorias.value.DataCadastro" [DataAlteracao]="formularioCategorias.value.DataAlteracao" [UsuarioCadastro]="formularioCategorias.value.UsuarioCadastro" [UsuarioModificacao]="formularioCategorias.value.UsuarioAlteracao"></app-auditoria>

<app-crud-buttons [showPost]="formularioCategorias.value.Id == guidInicial" [showPut]="formularioCategorias.value.Id != guidInicial" [showDelete] [labelBotaoPost]="'Gravar'" [labelBotaoPut]="'Gravar'" [labelBotaoAuxUm]="'Limpar formulário'" [disablePost]="formularioCategorias.status == 'INVALID' || EstadualControls.length == 0"
    [disablePut]="formularioCategorias.status == 'INVALID'  || EstadualControls.length == 0" [disableDelete]="formularioCategorias.value.Id == guidInicial" (OnClickButtonPost)="post()" (OnClickButtonPut)="post()" (OnClickButtonAuxUm)="limpar()" (OnClickButtonDelete)="delete()">
</app-crud-buttons>
